AOS.init({
  once: true,
});

( function( $, window, document, undefined ) {

  $('.flex-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    fade: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1480,
        settings: {
          arrows: false
        }
      }
     ]
  });

  $('.info-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1480,
        settings: {
          arrows: false
        }
      }
     ]
  });

  $('.plans-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1480,
        settings: {
          arrows: false
        }
      }
     ]
  });

  const $keyToggle = $('.key__toggle');
  const $keyInner = $('.key__inner');
  const $chevron = $keyToggle.find('.chevron');

  $keyToggle.on('click', function() {
  	$keyInner.stop().slideToggle();
    $keyToggle.toggleClass('is-open');
  })

  // Hamburger menu
  
  const $hamburger = $('.hamburger');
  const $mobilenav = $('.nav-container');

  $hamburger.on('click', function() {
    $(this).toggleClass('is-active');
    $mobilenav.toggleClass('active');
  });

  // Change hamburger colour on scroll

  $(window).scroll(function(){
      if ($(this).scrollTop() > 165) {
         $hamburger.addClass('scrolled');
      } else {
         $hamburger.removeClass('scrolled');
      }
  });

} )( jQuery, window, document );